<template>
  <div>
    <h1 class="headline mb-5">{{ $t('message.select_an_account') }}</h1>
    <v-list>
      <template v-for="(item, i) in accounts">
        <v-divider v-if="item.divider" :key="i" />
        <v-list-item v-else :key="i" @click="selectAccount(item)">
          <v-list-item-icon>
            <v-icon v-text="item.icon" />
          </v-list-item-icon>
          <v-list-item-content>
            <p class="text-body-1">{{ item.title }}</p>
            <v-list-item-subtitle v-text="item.subtitle" />
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
    <div class="text-center mt-6">
      <v-btn text rounded depressed color="primary" class="px-5" @click="$emit('loginCanceled')">
        {{ $t('common.cancel') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex';
import { getHeader, switchRoleUrl, userUrl } from '@/config/config';
import Alerts from '@/mixins/Alerts';
import { authUserService } from '@/services/auth-user';
import { AppError, clearString } from '@/utils';
import intercom from '@/services/intercom';
import { useContracts } from '@/use/Marketplace/useContracts';

export default {
  name: 'LoginAccountSelector',
  mixins: [Alerts],
  props: {
    accounts: {
      type: Array,
      default: () => [],
    },
  },

  setup(props, context) {
    const { contracts, fetchContracts } = useContracts({
      root: context.root,
    });

    return {
      contracts,
      fetchContracts,
    };
  },

  data() {
    return {
      loading: false,
    };
  },

  computed: {
    ...mapState({
      appName: state => state.app.name,
      appIntercomHide: state => state.app.intercom.hide,
      appIntercomId: state => state.app.intercom.id,
      appIntercomUserMaps: state => state.app.intercom.userMaps,
    }),
  },

  methods: {
    ...mapActions('app', ['loadConfigFromUser']),
    ...mapActions('layout', ['showAlert']),
    ...mapActions({
      setAuthUserData: 'account/setAuthUserData',
    }),
    ...mapMutations({
      setFeatureFlags: 'app/setFeatureFlags',
    }),
    async getAuthUser() {
      let response;

      try {
        response = await this.$http.get(userUrl, { headers: getHeader() });
      } catch (e) {
        throw new AppError({
          color: 'error',
          icon: 'mdi-alert-circle',
          message: 'Login error, please try again',
        });
      }

      this.authUserAppend(response.body);
      this.loadConfigFromUser();
      this.loadHelpServices();

      const {
        body: { features },
      } = response || {};
      await this.setAuthUserData();
      this.setFeatureFlags(features);

      if (features?.marketplace) {
        await this.fetchContracts(response.body.user.email);
      }
    },
    authUserAppend(authData) {
      window.localStorage.setItem(
        'auth_ehealth',
        JSON.stringify(this.authUserMapper(JSON.parse(window.localStorage.getItem('auth_ehealth')), authData))
      );
    },
    authUserMapper(authUser, data) {
      const isPatient = typeof data.patient !== 'undefined' && data.patient.id > 0;
      if (
        !isPatient &&
        typeof data?.empresa?.is_health_personnel !== 'undefined' &&
        data.empresa.is_health_personnel === 0
      ) {
        this.load_i18n_message('noHealthPersonnel');
      }

      return {
        ...authUser,
        email: data.user.email,
        name: data.user.name,
        fullName: !isPatient ? data.perfil.fullName : data.user.name + ' ' + data.user.surname,
        id: data.user.id,
        img: !isPatient ? data.perfil.img : '',
        lang: data.user.lang,
        nombreEmpresa: data?.empresa?.nombre || '',
        company_docline_api_id: data?.empresa?.docline_api_id || '',
        avatarEmpresa: data?.empresa?.avatar || '',
        logoEmpresa: data?.empresa?.logo || '',
        servicios_activos: !isPatient ? data.perfil.servicios_activos : [],
        rol: data.user.rol,
        roles: data.roles,
        user: data.user,
        trial: !isPatient ? data.perfil.trial : false,
        start_trial: !isPatient ? data.perfil.start_trial : '',
        formReserva: !isPatient ? data.perfil.formReserva : false,
        formPago: !isPatient ? data.perfil.formPago : false,
        formStepToStep: !isPatient ? data.perfil.stepToStep : false,
        permisos: !isPatient ? data.perfil.permisos : '',
        belong_to: !isPatient ? data.perfil.belong_to : 0,
        collegiateNumber:
          !isPatient && typeof data.perfil.collegiateNumber !== 'undefined' ? data.perfil.collegiateNumber : '',
        color: data?.empresa?.color || '',
        colorHexadecimal: data?.empresa?.colorHexadecimal || '',
        lastActivityTime: new Date(),
        permissions: !isPatient ? data.usuario.permissions : null,
        is_health_personnel: data?.empresa?.is_health_personnel || 1,
        is_collective: data?.empresa?.is_collective || 0,
        have_interconsultation: data?.empresa?.interconsultation === 1,
        erecipe: !isPatient && typeof data?.perfil?.erecipe !== 'undefined' ? data.perfil.erecipe : 0,
        hasSpecialtyToPrescribe:
          !isPatient && typeof data.perfil.hasSpecialtyToPrescribe !== 'undefined'
            ? data.perfil.hasSpecialtyToPrescribe
            : 0,
        company: {
          themeColorPrimary: data?.empresa?.themeColorPrimary || data?.empresa?.colorHexadecimal,
          themeColorSecondary: data?.empresa?.themeColorSecondary || data?.empresa?.colorHexadecimal,
          permissions: data?.empresa?.permissions || null,
          id: data?.empresa?.id || null,
          sharingReceptionist: data?.empresa?.sharingReceptionist,
        },
        isSuperAdmin: !isPatient ? data.usuario.isSuperAdmin : false,
        payment_module: !isPatient ? data.usuario.payment_module : true,
        isPatient: isPatient,
        patient: isPatient ? data.patient : false,
        show_informed_consent: data?.empresa?.informed_consent || 0,
        stripeConfigured:
          !isPatient && typeof data.perfil.stripeConfigured !== 'undefined' ? data.perfil.stripeConfigured : 0,
        environment: data?.environment || [],
      };
    },
    loadHelpServices() {
      const authUser = JSON.parse(window.localStorage.getItem('auth_ehealth'));

      let user = {};
      let showIntercom = false;
      if (authUser?.id) {
        user = { ...authUser };
        const name_env = this.appName;
        const user_id = user.id;
        user.id = this.appName + '_' + user.id;
        if (
          name_env.toLowerCase() === 'uniteco' &&
          typeof user.nombreEmpresa !== 'undefined' &&
          user.nombreEmpresa !== ''
        ) {
          user.id = clearString(user.nombreEmpresa) + '_' + user_id;
        }
        showIntercom = true;
      }

      if (this.appIntercomHide !== true && showIntercom) {
        !authUser?.isPatient && intercom(this.appIntercomId, this.appIntercomUserMaps, user);
      }
    },

    async selectAccount(item) {
      if (!this.loading) {
        this.loading = true;
        const param = {
          id: JSON.parse(window.localStorage.getItem('auth_ehealth'))['id'],
          role: item,
        };

        try {
          const res = await this.$http.post(switchRoleUrl, param, { headers: getHeader() });
          const role = {
            id: item.id,
            rol: item.roleId,
          };
          authUserService.setAuthUserRole(res.body, role);
          await this.getAuthUser();
          const isPatient = typeof res.body.patient !== 'undefined' && res.body.patient.id > 0;
          if (isPatient && window.localStorage.getItem('pathRequiresAuth')) {
            const pathRequiresAuth = JSON.parse(window.localStorage.getItem('pathRequiresAuth'));
            const now = new Date();
            const dateRequiresAuth = new Date(pathRequiresAuth.date);
            const timeBetween = (now - dateRequiresAuth) / (1000 * 60);
            if (!isNaN(timeBetween) && timeBetween < 60) {
              window.history.pushState('', '', pathRequiresAuth.fullPath);
            }
            localStorage.removeItem('pathRequiresAuth');
          }
          location.reload();
        } catch (err) {
          this.$log.error(err);
          this.showAlert({
            color: 'error',
            icon: 'mdi-alert-circle',
            message: err.body.error,
          });
        } finally {
          this.loading = false;
        }
      }
    },
    goToHome(data) {
      let routerPath = data.user.rol === 1 ? { name: 'Professionals' } : { name: 'Dashboard' };
      const isPatient = typeof data.patient !== 'undefined' && data.patient.id > 0;
      if (isPatient && window.localStorage.getItem('pathRequiresAuth')) {
        const pathRequiresAuth = JSON.parse(window.localStorage.getItem('pathRequiresAuth'));
        const now = new Date();
        const dateRequiresAuth = new Date(pathRequiresAuth.date);
        const timeBetween = (now - dateRequiresAuth) / (1000 * 60);
        if (!isNaN(timeBetween) && timeBetween < 60) {
          routerPath = pathRequiresAuth.fullPath;
        }
        localStorage.removeItem('pathRequiresAuth');
      }
      this.$router.push(routerPath);
    },
  },
};
</script>
